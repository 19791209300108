import { template as template_ef7480fff93c4b86ba8ca82126c3da6f } from "@ember/template-compiler";
const FKLabel = template_ef7480fff93c4b86ba8ca82126c3da6f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
