import { template as template_9c91a4ae60ba492bbf61f9c31121e941 } from "@ember/template-compiler";
const SidebarSectionMessage = template_9c91a4ae60ba492bbf61f9c31121e941(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
